import React from 'react'
import { Helmet } from 'react-helmet'

import { formationFrontend, introduction, program, modules } from 'data/data'

import {
  Layout,
  SEO,
  Footer,
  Course,
  Testimonial,
  Navbar,
  Header,
  Program,
  Modules,
  ContactArea,
  Row,
  Column,
  Button
} from 'components'

const CoursePage = () => (
  <Layout>
    <SEO
      title='Otterwise - Tecnologia, ruptura e autonomia'
      description={introduction.text}
      author='Otterwise'
      url='otterwise.co'
      keywords='HTML, CSS, Javascript, React, Curso Online, Otterwise, Realocação Profissional, Programação Web'
    />
    <Helmet title='Home' defer={false} />
    <Navbar />
    <Header
      noImage
      title='Programa de formação front-end'
      text='Essa formação te ajudará a começar ou aperfeiçoar a sua carreira como front-end através de um conteúdo totalmente alinhado com as reais necessidades do mercado.'
    />
    <Course
      title={formationFrontend.title || ''}
      subtitle={formationFrontend.subtitle || ''}
      text={{
        text_1: formationFrontend.text_1 || '',
        text_2: formationFrontend.text_2 || '',
        text_3: formationFrontend.text_3 || ''
      }}
      video={formationFrontend.video}
      schedule={formationFrontend.schedule || []}
      helper={false}
    />
    <Row width='100%' justifyContent='center' p='0 16px 16px' backgroundColor={['gray.150', 'white']}>
      <Column maxWidth={640} mt={28} fontWeight={700}>
        <a
          href='https://cursos.otterwise.co/semana-profissao-front-end?utm_source=site&utm_medium=cta-inscricao&utm_campaign=formacao-frontend'
          target='_blank'
        >
          <Button variant='secondary' fontWeight={700}>
            CADASTRE-SE PARA GARANTIR SUA PRÉ INSCRIÇÃO NA NOSSA TURMA DE AGOSTO
          </Button>
        </a>
      </Column>
    </Row>
    <Modules modules={modules} />
    <Program title={program.title || ''} image={program.image} imageDownload={program.download} />
    <Testimonial />
    <ContactArea />
    <Footer />
  </Layout>
)

export default CoursePage
